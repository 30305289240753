import React from 'react';

import { css } from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Icon } from 'src/components/atoms/Icon';
import { HubspotForm } from 'src/components/atoms/HubspotForm';
import { Box } from 'src/components/atoms/Box';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData, preHeading }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsHubspotForm');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <SectionHeader>
          {preHeading && <p><strong>{preHeading}</strong></p>}
          {!!heading && <h2>{heading}</h2>}
          {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
          {callouts.map(({ id, heading, contentNode, formId, sfCampaignId, formHeading, formContent, formContentNode }) => (
            <Grid key={id} verticalAlign="center" spacious {...customData?.gridProps}>
              <Column css={css`
                hyphens: none;
              `}>
                {!!formHeading && <h2>{formHeading}</h2>}
                {formContent.length > 0 && <MDXRenderer>{formContentNode.childMdx.body}</MDXRenderer>}
                <Box spacious>
                  <HubspotForm
                    customStyles={customData.hubspotFormStyles}
                    formId={formId}
                    sfdcCampaignId={sfCampaignId}
                  />
                </Box>
                {!!heading && <h2>{heading}</h2>}
                <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}></div>
              </Column>
            </Grid>
          ))}
        </SectionHeader>
        {ctas.length > 0 && (
          <Section primary>
            <Grid>
              {ctas.map((cta, index) => {
                const ctaCustomData = JSON.parse(cta.customData);
                return (
                  <Column key={index}>
                    <Grid columns="max-content 1fr" verticalAlign="center">
                    <Column>
                      {ctaCustomData?.icon && <Icon icon={ctaCustomData?.icon} size="3x" circle />}
                    </Column>
                    <Column>
                      {!!cta.heading && (
                        <h3>
                          {!!cta.url && <a href={cta.url}>{cta.heading}</a>}
                          {!cta.url && cta.heading}
                        </h3>
                      )}
                      <div
                        dangerouslySetInnerHTML={{__html: cta.text}}
                      />
                    </Column>
                    </Grid>
                  </Column>
                )
              })}
            </Grid>
          </Section>
        )}
      </Section>
    </MDXProvider>
  );
}
